<template>
  <div>
    <div class=input-date>
      <!-- 컨텐츠 -->
      <div @click.stop.prevent="openPicker"
          class="c-input-date-content unselect flex-align p-border-main radius-8"
          style="gap: 8px;padding:0 16px;height:52px;line-height: 52px">
        <!-- 아이콘 -->
        <i class="material-icons-outlined"
          :style="iconStyle">calendar_today</i>
        <div :style="dateDefaultStyle" class="full-width c-input-date-content-date"
        >{{ date }}</div>
      </div>
      <div class="input-date-mobile">
        <transition name="fade">
          <div @click.stop.prevent="closePicker"
              class="c-input-date-picker-bg"
              v-show="pickerOpened">
          </div> <!-- bg -->
        </transition>

        <!-- Picker -->
        <portal to="time-picker">
          <transition name="fade">
            <div class="c-input-date-picker"
                v-if="pickerOpened"
                v-on-clickaway="closePicker">
              <div class="lp-cal-padding" style="position: relative">
                <div class="lp-cal-select unselect">
                  <i @click="prev"
                    class="lp-cal-arrow material-icons">keyboard_arrow_left</i>

                  <div class="flex" style="position:relative">
                    <div class="lp-cal-title">
                      <span @click="clickYear" class="unselect">{{ year }}년</span>
                      <!-- 연 목록 -->
                      <div class="lp-modal-div lp-year" v-if="yearOpened">
                        <div :key="idx"
                            @click="selectYear(year)" class="lp-year-element unselect"
                            v-for="(year, idx) in modalYears">{{ year }}년</div>
                      </div>
                    </div>

                    <div class="lp-cal-title unselect first-upper">
                      <span @click="clickMonth">{{ months[month-1] }}</span>
                      <!-- 월 목록 -->
                      <div class="lp-modal-div lp-month" v-if="monthOpened">
                        <div :key="idx"
                            @click="selectMonth(idx+1)" class="lp-month-element unselect first-upper"
                            v-for="(mon, idx) in months">{{ mon }}</div>
                      </div>
                    </div>
                  </div>

                  <i @click="next"
                    class="lp-cal-arrow material-icons unselect">keyboard_arrow_right</i>
                </div>
              </div>
              <div style="height:322px">
                <div class="flex" style="padding:12px;padding-bottom: 0">
                  <!-- 요일 -->
                  <div
                      :class="{
                    'lp-sunday':idx===0,
                    'lp-day':idx>0
                  }" :key="'day'+idx"
                      v-for="(day, idx) in days">{{ day }}</div>

                  <!-- 날짜 -->
                  <div :class="{
                    'now': !prohibitBeforeDate && isNow(date),
                    'weight-300': prohibitBeforeDate && isBefore(date),
                    'weight-700': prohibitBeforeDate && !isBefore(date)
                  }"
                      :key="'date'+idx" @click="clickDate(idx)"
                      class="lp-date"
                      v-for="(date, idx) in dates">
                    <div v-if="date.year!==0">
                      <!-- 클릭된 날짜 모양 표시 -->
                      <div class="lp-click-date unselect bg-primary"
                          v-if="date.clicked">{{ date.day }}</div>
                      <!-- 일반 날짜 모양 표시 -->
                      <div :class="{
                      'unselect':  !prohibitBeforeDate || (prohibitBeforeDate && !isBefore(date)),
                      'prohibited': prohibitBeforeDate && isBefore(date)
                    }" class="lp-cal-date"
                          v-else>{{ date.day }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex" style="margin: 0px 8px 12px 0px;justify-content: flex-end">
                <div @click.stop.prevent="clickCancel"
                    class="lp-cal-bottom-btn-cancel unselect">취소</div>
                <div @click.stop.prevent="clickDone"
                    class="lp-cal-bottom-btn-confirm primary unselect">확인</div>
              </div>
            </div>
          </transition>
        </portal>
      </div>
    </div>
    <div class="lp-error-msg" v-if="validate && validate.error_msg && validate.error_msg !== ''">{{ validate.error_msg }}</div>
  </div>
</template>

<script>
  import { directive as onClickaway } from 'vue-clickaway';


  export default {
    name: 'input-date',
    props: {
      prohibitBeforeDate: {
        type: Boolean,
        default: false
      },
      beforeDate: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      validate: {
        type: Object
      },
      defaultNow: {
        type: Boolean,
        default: true
      }
    },
    directives: {
      onClickaway: onClickaway
    },

    created() {
      if(this.value) {
        let date = this.dayjs(this.value)
        this.year = date.year();
        this.month = date.month() + 1;
        this.day = date.date();
      }

      else {
        // 날짜 picker 현재 날짜로 설정.
        let now = this.dayjs();
        this.year = now.year();
        this.month = now.month() + 1;
        this.day = now.date();

      }
      
      this.date = this.dateToStr(this.year, this.month, this.day);

      this.setCalendar();
      if(this.defaultNow) {
        this.selectedYear = this.year;
        this.selectedMonth = this.month;
        this.selectedDay = this.day;
      } else if(!this.value){
        this.date = '';
      }
    },

    data() {
      return {
        days: ['일','월','화','수','목','금','토'],
        months: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
        pickerOpened: false,
        year: 0,
        month: 0,
        day: 0,
        dates: [],
        selectedYear: 0,
        selectedMonth: 0,
        selectedDay: 0,

        yearOpened: false,
        monthOpened: false,
        date: ''
      };
    },

    computed: {
      iconStyle() {
        let deco = {
          marginRight: '8px',
          color: '#AEAEAE'
        };
        return deco;
      },
      dateDefaultStyle() {
        if (!this.date || this.date === '') {
          return {
            color: '#828282'
          }
        } else {
          return {
            color: '#242428'
          }
        }
      },

      modalYears() {
        let years = []
        for(let i=this.dayjs().add(3, 'year').year(); i>=1940; i--) {
          years.push(i);
        }
        return years;
      },
      beforeDateObj() {
        return this.beforeDate ? {
          year: this.dayjs(this.beforeDate).year(),
          month: this.dayjs(this.beforeDate).month() + 1,
          day: this.dayjs(this.beforeDate).date()
        } : null;
      }
    },

    watch: {
      value(n) {
        let date = this.dayjs(n)
        this.date = this.dateToStr(date.year(), date.month()+1, date.date());
      }
    },

    methods: {
      dateToStr(year, month, day) {
        return this.dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
      },
      chkValid() {
        if(this.validate && this.validate.valid_required) {
          if (!this.date || this.date === '') {
            this.validate.error_msg = '날짜를 선택해주세요';
          } else {
            this.validate.error_msg = '';
          }
        }
      },
      openPicker() {
        if(this.disabled) return;
        if(this.date && this.date !== '' && this.date !== this.placeholder) {
          let dates = this.date.split('-');
          this.year = Number(dates[0]);
          this.month = Number(dates[1]);
          this.day = Number(dates[2]);
        }
        this.selectedYear = this.year;
        this.selectedMonth = this.month;
        this.selectedDay = this.day;
        this.setCalendar();
        this.pickerOpened = true;
      },

      closePicker() {
        this.pickerOpened = false;
        this.chkValid();
      },

      //캘린더 데이터 생성
      setCalendar() {
        // 캘린더 시작일
        let day = this.dayjs(this.dateToStr(this.year, this.month, 1))
          .startOf('month');

        // 캘린더 마지막일
        let end_day = this.dayjs(this.dateToStr(this.year, this.month, 1))
          .endOf('month').date();

        let dates = [];

        // 시작일 전 공백 채우기
        for(let i = 0; i < day.day(); i++) {
          dates.push({
            clicked: false,
            year: 0,
            month: 0,
            day: 0,
          });
        }

        for (let i = 1; i <= end_day; i++) {
          let clicked = this.dateToStr(this.selectedYear, this.selectedMonth, this.selectedDay) 
                        === this.dateToStr(day.year(), day.month() + 1, i);
          dates.push({
            clicked: clicked,
            year: day.year(),
            month: day.month() + 1,
            day: i
          });
        }
        this.dates = dates;
      },

      //이전 달
      prev() {
        let prevDt = this.dayjs(this.dateToStr(this.year, this.month, 1)).subtract(1, 'month');
        this.year = prevDt.year();
        this.month = prevDt.month() + 1;
        this.setCalendar();
      },
      //다음 달
      next() {
        let nextDt = this.dayjs(this.dateToStr(this.year, this.month, 1)).add(1, 'month');
        this.year = nextDt.year();
        this.month = nextDt.month() + 1;
        this.setCalendar();
      },

      isNow(date) {
        let now = this.dayjs();
        return date.year === now.year() &&
          date.month === now.month() + 1 &&
          date.day === now.date();
      },
      isBefore(date) {
        return this.dayjs(this.dateToStr(date.year, date.month, date.day)).isBefore(this.dayjs(this.beforeDate).add(1, 'day'), 'day');
      },

      //날짜 클릭시 원 표시
      clickDate(idx) {
        for(let i=0; i<this.dates.length; i++) {
          if(i === idx){
            if (this.prohibitBeforeDate) {
              let date = {
                year: this.dates[i].year,
                month: this.dates[i].month,
                day: this.dates[i].day
              }
              if (this.isBefore(date)) {
                this.dates[i].clicked = false;
              } else {
                this.dates[i].clicked = true;
                this.selectedYear = this.dates[i].year;
                this.selectedMonth = this.dates[i].month;
                this.selectedDay = this.dates[i].day;
              }
            } else {
              this.dates[i].clicked = true;
              this.selectedYear = this.dates[i].year;
              this.selectedMonth = this.dates[i].month;
              this.selectedDay = this.dates[i].day;
            }
          } else{
            this.dates[i].clicked = false;
          }
        }
      },

      clickYear() {
        this.yearOpened = true;
        this.monthOpened = false;
      },

      clickMonth() {
        this.yearOpened = false;
        this.monthOpened = true;
      },

      selectYear(year) {
        this.year = year;
        this.setCalendar();
        this.yearOpened = false;
      },

      selectMonth(month) {
        this.month = month;
        this.setCalendar();
        this.monthOpened = false;
      },


      clickCancel() {
        this.pickerOpened = false;
        this.chkValid();
      },

      clickDone() {
        this.year = this.selectedYear;
        this.month = this.selectedMonth;
        this.day = this.selectedDay;

        this.date = this.dateToStr(this.year, this.month, this.day);

        this.$emit('update:value', this.date);
        this.$emit('input', this.date);

        this.pickerOpened = false;
        this.chkValid();
      }
    }
  }
</script>

<style lang="stylus" type="stylus" scoped>
  .input-date
    position relative
    border 1px solid #ddd
    border-radius 8px


  .prohibited
    cursor not-allowed
    -ms-user-select none
    -moz-user-select -moz-none
    -khtml-user-select none
    -webkit-user-select none
    user-select none
  .c-input-date-content
    display flex

  .c-input-date-content-date
    font-size 15px

  .c-input-date-picker-bg
    position fixed
    z-index 9999
    top 0
    right 0
    bottom 0
    left 0
    background-color rgba(0, 0, 0, 0.3)

  .c-input-date-picker
    width 360px
    position fixed
    left 50%
    bottom 20%
    transform translateX(-50%)
    border 1px solid #ddd
    box-shadow 0px 3px 4px 2px #00000029
    z-index 9999
    background-color white

  .flex
    flex-wrap wrap
  .lp-cal-padding
    padding 20px
    padding-bottom 0

  .lp-cal-select
    display flex
    justify-content center
    padding 0
    padding-bottom 12px

  .lp-cal-title
    text-align center
    line-height 25px
    position relative
    margin 0 4px

  .lp-cal-arrow
    font-size 1.6rem
    padding 0 12px
    cursor pointer

  .lp-sunday
  .lp-day
  .lp-date
    text-align center
    padding 8px

  .lp-sunday
  .lp-day
    width 14.285714%
    font-size 0.8rem

  .lp-sunday
    color #fc0000

  .lp-day
    color #787878

  .lp-date
    width 14.285714%
    height 44px
    color #777777
    position relative

  .now
    font-weight bold

  .lp-cal-date
  .lp-done-date
  .lp-click-date
    margin 0 auto
    border-radius 28px
    width 28px
    height 28px
    line-height 29px
    font-size 0.82rem

  .lp-click-date
    color white

  .lp-done-date
    background-color #ececec
    color #787878

  .lp-done-date-click
    border 1px solid #787878

  .lp-cal-bottom-btn-confirm
    text-align center
    padding 12px
    border-radius 3px

  .lp-cal-bottom-btn-cancel
    padding 12px
    text-align center
    margin-right 12px
    border-radius 3px

  /* 모달 부분 */

  .lp-year-element
  .lp-month-element
    margin 12px
    text-align left
    font-size 15px

  .lp-year-element:hover
  .lp-month-element:hover
    font-weight bold
    cursor pointer

  .lp-year-element
    width 58px

  .lp-month-element
    width 42px

  .lp-modal-div
    background white
    position absolute
    top 30px
    left -12px
    z-index 11
    width 110px
    height 240px
    overflow-y scroll
    border 1px solid #ddd

  .fade-enter-active
    transition all .3s

  .fade-leave-active
    transition all 3s reverse

  .fade-enter, .fade-leave
    opacity 0

  .drawer-enter-active
    transition all .3s

  .drawer-leave-active
    transition all .3s reverse

  .drawer-enter, .drawer-leave
    bottom -400px

  .lp-error-msg
    color #e80000
    font-size 0.7rem
    margin-top 4px
    margin-bottom 12px
    width: 100%

  .bg-primary
    background-color #ff6600
</style>
